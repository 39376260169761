@import "../../../../styles/variables.scss";


$checkbox-spacing: 10px;

.checkboxes {
    margin: 0 auto;
    padding-left: 30%;
  
  input[type="checkbox"] {
    margin-bottom: $checkbox-spacing;
  }
}


  
  
  
  
  
  
  

