@import "../../../../styles/variables.scss";

.reports-style-f {
  .report-header {
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 1.6rem;
    .blue {
      color: #3399ff;
      font-weight: 600;
    }
    .gray {
      color: #828282;
      font-weight: 500;
    }
  }
  .reports-container {
    width: 100%;
    position: relative;
    padding: 2% 2%;
    .form-btn {
      width: 10%;
      border: none;
      height: 1.8rem;
      line-height: 1rem;
      background-color: $primary-blue;
      &.secundary {
        background-color: #00C1ED;
      }
    }
    &.card-shadow {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.35);
    }
    &-large {
      height: 15rem;
    }
    .ant-input,
    .ant-picker,
    .ant-select,
    .ant-select-selector {
      width: 100%;
      border-radius: 5px;
    }
  }
  .filter-card,
  .filter-card-large {
    height: 25rem;
    .ant-card-body {
      padding: 0.8rem 2rem;
    }
    .card-title {
      color: #919191;
      font-weight: 500;
      font-size: 1.4rem;
    }
    .report-title {
      color: #919191;
    }
    .input-container {
      label {
        color: #919191;
      }
    }
    &.card-shadow {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.35);
    }
    &-large {
      height: 15rem;
    }
  }

  padding: 0.8% 6%;
  .header-container {
    margin: auto;
    width: 65%;

    .title {
      margin-top: 5rem;
      font-size: 1.3rem;
      font-weight: 600;
      text-align: left;
    }
  }

  .table-container {
    margin: auto;
    .bill-selection {
      margin-top: 3rem;
      .select-label {
        text-align: left;
        font-size: 1rem;
        display: block;
        margin: 0;
        padding: 0;
      }
    }

  }

  .ant-pagination {
    font-size: 1rem;
    .ant-pagination-item {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      min-width: 25px;
      height: 25px;
      line-height: 26px;
    }
    .ant-pagination-options-size-changer.ant-select {
      .ant-select-selector {
        height: 25px;
      }
      .ant-select-selection-item {
        line-height: 24px;
      }
    }
  }
}
